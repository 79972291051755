import * as storage from "../services/storage";

///if more than 60 minuted difference, return false
export const checkLastTimeSynced = (num) => {
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var syncDate;
  var timeDate;
  if (num == 1) {
    syncDate = storage.local.getItem("dateSync1");
    timeDate = storage.local.getItem("timeSync1");
  } else if (num == 2) {
    syncDate = storage.local.getItem("dateSync2");
    timeDate = storage.local.getItem("timeSync2");
  } else return false;
  if (syncDate == null || timeDate == null) return false;
  var now = new Date(
    date.split("-")[0],
    date.split("-")[1],
    date.split("-")[2],
    time.split(":")[0],
    time.split(":")[1],
    time.split(":")[2]
  );
  var syncedAt = new Date(
    syncDate.split("-")[0],
    syncDate.split("-")[1],
    syncDate.split("-")[2],
    timeDate.split(":")[0],
    timeDate.split(":")[1],
    timeDate.split(":")[2]
  );
  var diff = (syncedAt.getTime() - now.getTime()) / 1000;
  diff /= 60;
  var minutesSinceSync = Math.abs(Math.round(diff));

  if (minutesSinceSync > 30) return false;
  return true;
};

export const supportStorage = () => {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};
