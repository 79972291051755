import React, { Component } from "react";
// import CustomForm from "../components/CustomForm/CustomForm.jsx";
import LoginService from "../services/LoginService.js";
import i18n from "../i18configuration/i18";
import withRouter from "react-router-dom/withRouter";
import { setJWT, setUserId } from "../services/TokenService";
import { NavLink } from 'react-router-dom'
import NotificationSystem from "react-notification-system";
import CustomForm from "components/CustomForm/CustomForm.jsx";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waitingResponse: false,
    };
  }
  notificationSystem = React.createRef();

  componentDidMount() {
    var notification = this.notificationSystem.current;
  }
  InputGroup = [
    {
      label: {
        text: i18n.t("Email:"),
        class: "align-self-start",
      },
      inputClass: "w-100 radius-top-left-4 radius-bottom-left-4  ",
      type: "email",
      placeholder: i18n.t("email_placeholder"),
      disabled: false,
      inputName: "email",
      formGroup: "vertical-flex ",
      inputDiv: "login-el",
    },
    {
      label: {
        text: i18n.t("Fjalëkalimi:"),
        class: "align-self-start",
      },
      inputClass: "",
      type: "password",
      placeholder:  i18n.t("pass_placeholder"),
      disabled: false,
      inputName: "password",
      formGroup: "vertical-flex",
      inputDiv: "login-el relative",
    },
    {
      label: {
        text: "",
        class: "",
      },
      inputClass: "",
      type: "submit",
      placeholder: "",
      disabled: false,
      value: i18n.t("login"),
      inputName: "login",
      formGroup: "flex flex-center",
    },
  ];

  LoginService = new LoginService();
  validData = (data) => {
    if (
      data == undefined ||
      data.email == undefined ||
      data.password == undefined
    )
      return false;
    if (data == null) return false;
    if (data.email.trim() == "" || data.password.trim() == "") return false;
    return true;
  };
  onsubmit = async (data) => {
    this.setState({
      waitingResponse: true,
    });
    var notification = this.notificationSystem.current;
    if (!this.validData(data)) {
      this.setState({
        waitingResponse: false,
      });
      notification.addNotification({
        message: i18n.t("Ju lutem te mbushni te dhenat"),
        level: "warning",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
      return;
    }
    data.email = data.email.toLowerCase();
    if (data.email && data.password) {
      await this.LoginService.login(data)
        .then((res) => {
          setUserId(res.data.userID);
          setJWT(res.data.token);
          this.sendToDashboard();
        })
        .catch((err) => {
          this.setState({
            waitingResponse: false,
          });
          notification.addNotification({
            message: i18n.t("Të dhënat janë gabim, provoni përsëri"),
            level: "error",
            position: "tc",
            autoDismiss: "5",
            dismissible: "both",
          });
        });
    } else {
      this.setState({
        waitingResponse: false,
      });
      notification.addNotification({
        message: i18n.t("Ju lutem të shenoni username dhe fjalëkalimin tuaj"),
        level: "warning",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
    }
  };
  sendToDashboard = () => {
    this.props.history.push({
      pathname: "/user/dashboard",
    });
  };

  render() {
    return (
      <div className="w-100 height-100-vh login-page">
        <div className="h-100 login-wrap-container">
          <div className="login-wrapper flex vertical-flex h-50 border align-center left-15 box-center-horizontal relative top- bg-white no-border top-25 p-3 radius-10">
            <NotificationSystem ref={this.notificationSystem} />
            <h4 className="text-center m-0">
              {i18n.t('new user')}
              <NavLink to="/user/register">{i18n.t('register')} </NavLink>
            </h4>
            <CustomForm
              waitingResponse={this.state.waitingResponse}
              handleSubmit={(e) => this.onsubmit(e)}
              formclass="align-center flex vertical-flex flex-space-around inherit-all login-form"
              data={this.InputGroup}
            />
            <NavLink to="/user/reset-password/login/notoken">
            {i18n.t('reset pass')}
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
