import LoginService from "services/LoginService";
import { deleteToken } from "services/TokenService";
import { setJWT } from "services/TokenService";

export const checkToken = () => {
  new LoginService().checkToken()
    .then((res) => {
      setJWT(res.data.token);
    })
    .catch((err) => {
      logout();
    });
};


const logout = () => {
  deleteToken();
};
